<template>
  <div class="home">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="deviceBack">
            <i class="el-icon-arrow-left" @click="back()"></i>
          </div>
          <div class="header_title">
            地址信息管理
            <div class="loginJump" @click="manageAddress">{{ isCheckOut ? "取消" : "管理" }}</div>
          </div>
        </div>
      </el-header>
      <el-main style="background-color: #f1f1f1;">
        <div class="addressPart">
          <div
            class="addressList"
            v-for="(item, index) in addressList"
            :key="index"
          >
            <div class="leftIcon">
              <div class="showIcon" v-show="item.isNess === 1"><i class="el-icon-s-home" /></div>
              <div class="showName" v-show="item.isNess !== 1">{{ item.name.length > 2 ? item.name.substr(0, 1) : item.name }}</div>
            </div>
            <div class="rightInside">
              <div class="highTop">
                <b>{{ item.name }}</b>
                <span>{{ item.mobile }}</span>
                <span class="inDefault">默认</span>
              </div>
              <div class="highAddress">{{ item.address }}21312312fsdfsdf12321fdsffsdfsdfsdfsdfsdfsdfsdfsdfdsfsdfsdf</div>
            </div>
            <div class="rightIcon" @click="goToDetails(isCheckOut, item.id)" v-if="formType === 0">
              <i v-if="!isCheckOut" class="el-icon-edit" />
              <i v-else class="el-icon-delete" />
            </div>
            <div class="rightIcon" style="width: 80px;" @click="getChoose(item.id)" v-else-if="formType === 1">
              <el-button size="mini" type="success">确定</el-button>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalPage: 0,
      pageIndex: 1,
      pageLimit: 10,
      username: null, // 用户名称
      isCheckOut: false,
      formType: 0,
      addressList: [
        {
          id: 1,
          name: "某系人",
          mobile: "13679830253",
          address: "青田区选择人",
          isNess: 1
        }
      ]
    }
  },
  mounted() {
    this.formType = this.$route.query.type;
    // this.getDataList();
  },
  methods: {
    getDataList() {
      this.$http.httpGet(`/cms/appAnnouncement/list`, {
        page: this.pageIndex,
        limit: this.pageLimit,
      }).then(data =>{
        if (data && data.code === 0) {
          if (this.pageIndex > 1) {
            this.addressList = this.addressList.concat(data.page.list);
          } else {
            this.addressList = data.page.list;
          }
          this.totalPage = data.page.totalPage;
        } else {
          this.addressList = [];
          this.totalPage = 0;
        }
      })
    },
    manageAddress() {
      this.isCheckOut = !this.isCheckOut
    },
    // 跳转到预约详情
    goToDetails(ck, id) {
      if (ck) {
        this.$confirm('是否确认删除该地址信息?', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(1)
        }).catch(() => {
          console.log(2)
        });
      } else {
        this.$router.replace({ name: "addressDetail", query: {id: id} });
      }
    },
    getChoose(id) {
      this.$cookies.set("addressId", id);
      this.$router.replace("orderComfirm");
    },
    back() {
      if (parseInt(this.formType) === 0) {
        this.$router.replace("My");
      } else {
        this.$router.replace("orderComfirm");
      }

    }
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .loginJump {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 10px;
    }
    .el-main {
      padding: 0 !important;
      .addressPart {
        position: relative;
        display: block;
        .addressList {
          position: relative;
          overflow: hidden;
          background-color: #fff;
          display: flex;
          flex-wrap: wrap;
          .leftIcon {
            width: 40px;
            padding: 10px;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .showIcon {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-image: linear-gradient(rgba(41, 143, 255, 0.6), rgba(41, 143, 255, 1));
              i {
                font-size: 16px;
                color: #fff;
              }
            }
          }
          .rightInside {
            flex: 1;
            position: relative;
            padding: 10px 10px 10px 0;
            .highTop {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding: 8px 0;
              b {
                font-size: 16px;
                color: #000;
                line-height: 20px;
                font-weight: 600;
              };
              span {
                font-size: 14px;
                color: #333;
                line-height: 20px;
                margin: 0 0 0 10px;
              }
              .inDefault {
                border: 1px #298FFF solid;
                font-size: 12px;
                padding: 0 4px;
              }
            }
            .highAddress {
              font-size: 13px;
              line-height: 1.4;
              word-break: break-all;
            }
          }
          .rightIcon {
            width: 40px;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 18px;
              color: #999;
            }
          }
          .announceTop {
            font-size: 16px;
            padding: 8px 10px;
            font-weight: 600;
            line-height: 1.4;
            max-height: 40px;
            letter-spacing: 1px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            border-bottom: 1px #efefef solid;
          }
          .announceCon {
            font-size: 13px;
            padding: 15px 10px 0;
            text-indent: 2em;
            line-height: 1.6;
            max-height: 52px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .announceBottom {
            margin: 15px 0 0;
            border-top: 1px #efefef solid;
            font-size: 13px;
            color: #000;
            line-height: 1.6;
            padding: 10px;
            text-align: right;
          }
        }
      }
    }
    .el-footer {
      .footerPagination {
        position: relative;
        padding: 13px 0 14px;
        margin: 0 auto;
        display: block;
        text-align: center;
      }
    }
  }
}
</style>
